import { RoutePathsType } from "../types/routePaths";

export const RoutePaths: RoutePathsType = {
  NavBar: {
    primaryLinks: [
      {
        name: "People",
        url: "/people",
      },
      {
        name: "Influence",
        url: "/influence",
      },
      {
        name: "Lifestyle",
        url: "/lifestyle",
      },
      {
        name: "Watches & Jewellery",
        url: "/watch-jewellery",
      },
    ],
    utilityLinks: [
      {
        id: "magazineIssues", // allow overwrite of name and url from data
        name: "Subscribe",
        url: "https://subscribe.sph.com.sg/package/subscribe-harpers-bazaar-singapore/",
        as: "button",
        variant: "primary",
        target: "_blank",
      },
    ],
    searchBarLink: {
      name: "Search",
      url: "/search",
    },
    mobileUtilityLinks: {
      // login: {
      //   name: "Login",
      //   url: "/",
      // },
    },
  },
  Footer: [
    // make sure to update following css grid token if changing the number of items
    // --header-mega-menu-footer-items-grid-template
    {
      text: "About Us",
      url: "/about-us",
    },
    {
      text: "Advertise With Us",
      url: "https://www.sph.com.sg/advertising-solutions/",
      target: "_blank",
    },
    {
      text: "Contact",
      url: "https://www.sph.com.sg/contact-us/",
      target: "_blank",
    },
    {
      text: "Terms Of Service",
      url: "https://www.sph.com.sg/legal/website_tnc/",
      target: "_blank",
    },
    {
      text: "Privacy Policy",
      url: "https://www.sph.com.sg/legal/sph_privacy/",
      target: "_blank",
    },
    {
      text: "PDPA",
      url: "https://www.sph.com.sg/legal/pdpa/",
      target: "_blank",
    },
  ],
  MegaMenu: [
    {
      text: "People",
      url: "/people",
      placement: "left",
      subMenu: [
        {
          text: "Business Leaders",
          url: "/tag/business-leaders",
        },
        {
          text: "CEOs Abroad",
          url: "/tag/ceos-abroad",
        },
        {
          text: "Creatives",
          url: "/tag/creatives",
        },
        {
          text: "Next Gen",
          url: "/tag/next-gen",
        },
      ],
    },
    {
      text: "Lifestyle",
      url: "/lifestyle",
      placement: "right",
      subMenu: [
        {
          text: "Arts",
          url: "/tag/arts",
        },
        {
          text: "Cars",
          url: "/tag/cars",
        },
        {
          text: "Gourmet",
          url: "/tag/gourmet",
        },
        {
          text: "Homes",
          url: "/tag/homes",
        },
        {
          text: "Style",
          url: "/tag/style",
        },
        {
          text: "Tech",
          url: "/tag/tech",
        },
        {
          text: "Travel",
          url: "/tag/travel",
        },
        {
          text: "Wellness",
          url: "/tag/wellness",
        },
      ],
    },
    {
      text: "Influence",
      url: "/influence",
      placement: "left",
      subMenu: [
        {
          text: "Business Updates",
          url: "/tag/business-updates",
        },
        {
          text: "Career",
          url: "/tag/career",
        },
        {
          text: "Opinion",
          url: "/tag/opinion",
        },
      ],
    },
    {
      text: "Watches & Jewellery",
      url: "/watch-jewellery",
      placement: "right",
      subMenu: [
        {
          text: "Jewellery",
          url: "/tag/jewellery",
        },
        {
          text: "Watches",
          url: "/tag/watches",
        },
      ],
    },
    {
      text: "The Peak Power List",
      url: "/topics/peak-power-list",
      placement: "full",
      subMenu: [],
    },
  ],
};
