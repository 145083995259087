import { ConfigHeader } from "../../types/config";
import * as Icons from "./icons/socialIcon";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Enjoy in-depth interviews from business leaders, profiles on Singapore’s movers and shakers, sharp commentary, and more in your inbox every week.",
      buttonText: "SIGN UP",
      url: "/newsletter-signup",
    },
    closeButton: {
      type: "icon",
      placement: "right",
    },
    divider: {
      footer: false,
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.linkedinIcon,
        name: "Linkedin",
        href: "https://www.linkedin.com/company/thepeaksingapore/",
      },
      {
        icon: Icons.instagramIcon,
        name: "Instagram",
        href: "https://www.instagram.com/thepeaksg/",
      },
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "https://www.facebook.com/ThePeakSingapore",
      },
      {
        icon: Icons.youtubeIcon,
        name: "Youtube",
        href: "https://www.youtube.com/channel/UCsSYfcPLJwgLoSxWvStzpPg",
      },
    ],
  },
};

export const hamburgermenuSvgSource = (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.170898" width="24" height="2" fill="black" />
    <rect x="0.170898" y="7" width="24" height="4" fill="black" />
    <rect x="0.170898" y="16" width="24" height="2" fill="black" />
  </svg>
);
